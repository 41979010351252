import React from "react";
import {
  PageTitle,
  PageHeadingArea,
  PageContent,
  Root,
  ResponsiveLayoutWrapper,
} from "@backslashbuild/contractors-ui-library";

import ContractorTopBar from "../modules/contractors/ContractorTopBar";
import NavBar from "../components/NavBar";

import AnytimeWagesForm from "../modules/aws/AnytimeWagesForm";
import AcceptContractDialog from "../modules/contractors/AcceptContractDialog";

function MyWallet() {
  return (
    <Root>
      <PageContent scrollable>
        <ContractorTopBar />
        <NavBar />
        <PageHeadingArea>
          <PageTitle>Anytime wages</PageTitle>
        </PageHeadingArea>
        <ResponsiveLayoutWrapper>
          <div>
            Approved advances will appear in your bank account within an hour,
            24 hours a day. Some banks may take longer to process the payment
          </div>
        </ResponsiveLayoutWrapper>
        <AnytimeWagesForm />
      </PageContent>
      <AcceptContractDialog />
    </Root>
  );
}

export default MyWallet;
