import React from "react";
import {
  PageContent,
  PageTitle,
  PageHeadingArea,
  Link,
  PageSubtitle,
} from "@backslashbuild/contractors-ui-library";

import ContractorTopBar from "../modules/contractors/ContractorTopBar";
import NavBar from "../components/NavBar";
import InformationSectionButton from "../components/InformationSection/InformationSectionButton";
import BlueBanner from "../components/InformationSection/BlueBanner";
import FlexCenter from "../components/InformationSection/FlexCenter";
import AcceptContractDialog from "../modules/contractors/AcceptContractDialog";

function CopingWithCovid() {
  return (
    <>
      <ContractorTopBar />
      <PageContent scrollable>
        <NavBar />
        <PageContent border>
          <PageHeadingArea>
            <PageTitle>COPING WITH COVID-19</PageTitle>
          </PageHeadingArea>
          <PageSubtitle>
            Coronavirus, Self-isolation and Pay Expectations
          </PageSubtitle>
        </PageContent>
        <BlueBanner>Expecting a payroll as a furloughed worker? </BlueBanner>
        <PageContent border>
          <br />
          <div>
            Payroll for furloughed workers is processed every Friday with
            notification of completion, payslip and funds paid by 19.00 hours
            (subject to a bank holiday week).
          </div>
          <br />
        </PageContent>
        <BlueBanner>Job retention/ Furloughed Worker Scheme</BlueBanner>
        <PageContent border>
          <br />
          <div>
            People Group Services will constantly monitor the latest advice from
            government with reference to actions required for Coronavirus.
          </div>
          <br />
          <div>
            In response to the guidelines regarding the government job retention
            scheme/furloughed worker status.
          </div>
          <br />
          <div>
            <Link
              href={
                "https://www.govin.uk/guidance/claim-for-wage-costs-through-the-coronavirus-job-retention-scheme"
              }
            >
              https://www.govin.uk/guidance/claim-for-wage-costs-through-the-coronavirus-job-retention-scheme
            </Link>
          </div>
          <br />
          <div>
            We have got to work immediately to implement a solution to ensure
            those who qualify under the job retention scheme will be paid in
            accordance with the criteria that has been set. This does require
            some input from all concerned to ensure that you are treated fairly
            and according to the guidelines and the funding available.
          </div>
          <br />
          <PageSubtitle>Update 08th April 2020</PageSubtitle>
          <div>
            We successfully made updated payments to umbrella contractors for
            6am Monday morning following initial furlough payroll on Friday 03
            April. We continue to work closely with our agencies and contractors
            to ensure eligible workers are paid in line with updated guidance.
          </div>
          <br />
          <div>Further detail can be found in our Press Release.</div>
          <br />
          <PageSubtitle>Update 03rd April 2020</PageSubtitle>
          <div>
            We have successfully processed our first payroll to those
            contractors who qualified to be placed into a furloughed status
            under the job retention scheme.
          </div>
          <br />
          <div>
            This was not easy considering the ever moving goalposts and the
            commitment required by all parties concerned, the end client, the
            agency, the contractor and People Group Services. There will be more
            to come but we have done what we could in the very short time frame.
          </div>
          <br />
          <div>
            If you have been furloughed and paid today but are wondering how
            your pay was calculated please refer to these documents for
            assistance.
          </div>
          <br />
          <div>
            Paid through the umbrella solution{" "}
            <Link
              href={
                "https://peoplegroupservices.com//wp-content/uploads/2020/04/PGS-Furloughed-Pay-Calculation-Umbrella.pdf"
              }
            >
              https://peoplegroupservices.com//wp-content/uploads/2020/04/PGS-Furloughed-Pay-Calculation-Umbrella.pdf
            </Link>
          </div>
          <br />
          <div>
            Paid by the Peo/PAYE soulution{" "}
            <Link
              href={
                "https://peoplegroupservices.com//wp-content/uploads/2020/04/PGS-Furloughed-Pay-Calculations-PAYEPEO.pdf"
              }
            >
              https://peoplegroupservices.com//wp-content/uploads/2020/04/PGS-Furloughed-Pay-Calculations-PAYEPEO.pdf
            </Link>
          </div>
          <br />
          <PageSubtitle>Update 01st April 2020</PageSubtitle>
          <div>
            The Freelancer & Contractor Services Association is seeking to
            ensure that all agency workers, including umbrella workers, receive
            furloughed pay calculated on average earnings. It is possible that
            some may only receive 80% of National Minimum Wage compared to
            others who do the same job but might receive 80% of their average
            wages.
          </div>
          <br />
          <div>Full article here:</div>
          <br />
          <div>
            <Link
              href={
                "https://www.fcsa.org.uk/job-retention-scheme-will-furloughed-umbrella-employees-pay-be-calculated-fairly/"
              }
            >
              https://www.fcsa.org.uk/job-retention-scheme-will-furloughed-umbrella-employees-pay-be-calculated-fairly/
            </Link>
          </div>
          <br />
          <div>
            A soon as we are able to implement the process to ensure those who
            genuinely qualify under the job retention scheme and the calculation
            of what is expected to be paid has been agreed, we will contact you.
          </div>
          <br />
          <div>
            We appreciate this is taking longer than you'd expect and that the
            guidance is unclear and changing regularly but please remember,
            People Group are funding this before being able to claim
            reimbursement from Government, we must get it right and in strict
            adherence to the current guidelines to ensure we do not breach the
            guidance and jeopardise future funding.
          </div>
        </PageContent>
        <BlueBanner>
          Contingency plans - We are now working remotely!
        </BlueBanner>
        <PageContent border>
          <br />
          <div>
            We have tested our contingency plans so we are able to operate
            remotely without any impact on our day to day running.
          </div>
          <br />
          <div>
            Our premises are now closed, we will ensure continuity of service
            for our recruitment agencies and contractors. However, there may be
            slightly more reliance on digital communication, such as email,
            rather than landline phone calls.
          </div>
          <br />
          <div>Please contact us by using one of the following methods.</div>
          <br />
          <div>'Contact Us' tab in your portal.</div>
          <br />
          <div>
            Web chat - just click on the speech bubble in the bottom left hand
            corner. During office hours we will endeavour to respond within 5
            minutes.
          </div>
          <br />
          <div>
            Email -{" "}
            <Link href={"mailto:Clientcare@peoplegroupservices.com"}>
              Clientcare@peoplegroupservices.com
            </Link>
            , during office hours we will endeavour to respond within the hour.
          </div>
          <br />
          <div>
            Should you need a call please use one of the above methods to
            request this and we will get in touch at a time suitable for you.
          </div>
        </PageContent>
        <BlueBanner>Self-Isolation and sickness</BlueBanner>
        <PageContent border>
          <br />
          <div>
            Contractors who are in self-isolation because of personal sickness
            or sickness in the household may be entitled to SSP. However, in
            order for claims to be accepted they MUST be accompanied by a valid
            assignment schedule as proof of upcoming work.
          </div>
          <br />
          <div>
            Individuals can self-certify for 7 days and may have up to 14 days
            with an NHS 111 certificate.
          </div>
          <br />
          <div>School Closures</div>
          <br />
          <div>
            With all schools in England set to close to the vast majority of
            students on Friday 20th March, contractors who find themselves
            without work will unfortunately not be entitled to claim SSP.
          </div>
          <br />
          <div>
            Instead, they should apply for universal credit on the link below:
          </div>
          <br />
          <div>
            <Link
              href={
                "https://www.understandinguniversalcredit.gov.uk/coronavirus/"
              }
            >
              https://www.understandinguniversalcredit.gov.uk/coronavirus/
            </Link>
          </div>
          <br />
          <div>
            We understand that for some people this presents a difficult
            situation. With advice changing all the time we will endeavour to
            stay updated and continue to provide guidance and assistance where
            possible. We are continuing to service our customers so please get
            in touch on email, phone or webchat if you have any questions. Our
            phone lines are very busy, so please do also consider email and
            webchat.
          </div>
          <br />
          <div>
            <Link href={"mailto:clientcare@peoplegroupservices.com"}>
              clientcare@peoplegroupservices.com
            </Link>
          </div>
          <br />
          <div>
            Webchat is available on the contractor portal and our website.
          </div>
        </PageContent>
        <BlueBanner>What can you do?</BlueBanner>
        <PageContent border>
          <br />
          <div>
            We are now into the containment phase of the outbreak. This involves
            limiting the spread of the virus in order to reduce the number of
            new cases and allow the NHS to treat the most affected patients.
            What can you do? Well, the answer is simple. Follow the Government
            measures closely; this can be as simple as properly washing your
            hands at every available opportunity, but also involves social
            distancing and being strict on self-isolation.
          </div>
          <br />
          <div>Top tips:</div>
          <div>
            <ul>
              <li>Wash your hands with soap for 20 seconds at a time</li>
              <li>Avoid touching your face</li>
              <li>Use a tissue or sleeve to cover coughs and sneezes</li>
              <li>Stay at least three steps away from other people</li>
              <li>Avoid close contract with people who are unwell</li>
              <li>
                Follow self-isolation guidelines if you or someone you've been
                in contact with shows symptoms of the virus
              </li>
              <li>
                For more information:{" "}
                <Link href={"https://www.bbc.co.uk/news/health-51711227"}>
                  https://www.bbc.co.uk/news/health-51711227
                </Link>
              </li>
            </ul>
          </div>
          <br />
          <div>
            We will continue to monitor the situation and the advice given and
            will update accordingly.
          </div>
        </PageContent>
        <BlueBanner>Useful links</BlueBanner>
        <PageContent border>
          <br />
          <div>
            <Link
              href={
                "https://www.bbc.co.uk/news/topics/cyz0z8w0ydwt/coronavirus-outbreak"
              }
            >
              https://www.bbc.co.uk/news/topics/cyz0z8w0ydwt/coronavirus-outbreak
            </Link>
          </div>
          <br />
          <div>
            <Link
              href={
                "https://www.gov.uk/government/organisations/public-health-england"
              }
            >
              https://www.gov.uk/government/organisations/public-health-england
            </Link>
          </div>
          <br />
          <div>
            <Link
              href={
                "https://www.gov.uk/guidance/coronavirus-covid-19-information-for-the-public"
              }
            >
              https://www.gov.uk/guidance/coronavirus-covid-19-information-for-the-public
            </Link>
          </div>
          <br />
        </PageContent>
      </PageContent>
      <AcceptContractDialog />
    </>
  );
}

export default CopingWithCovid;
