import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import luxonUtils from "@date-io/luxon";
import {
  SnackProvider,
  StyleDefaults,
  StyleWrapper,
  Root,
  Link,
  PageLoading,
  DateUtilsProvider,
} from "@backslashbuild/contractors-ui-library";
import {
  AuthenticationProvider,
  oidcLog,
  useReactOidc,
} from "@axa-fr/react-oidc-context";

import { UserIdProvider } from "./UserIdContext";
import Home from "./pages/Home";
import Payslips from "./pages/Payslips";
import YourAccount from "./pages/YourAccount";
import AnytimeWages from "./pages/AnytimeWages";
import EditPersonalDetails from "./pages/EditPersonalDetails";
import EditBankDetails from "./pages/EditBankDetails";
import EditContactDetails from "./pages/EditContactDetails";
import EditLoginDetails from "./pages/EditLoginDetails";
import MyWallet from "./pages/MyWallet";
import YourPointsStatement from "./pages/myWallet/YourPointsStatement";
import LearningDevelopment from "./pages/myWallet/LearningDevelopment";
import Tastecard from "./pages/myWallet/Tastecard";
import Boxx from "./pages/myWallet/Boxx";
import PureCard from "./pages/myWallet/PureCard";
import Medicash from "./pages/myWallet/Medicash";
import CopingWithCovid from "./pages/CopingWithCovid";

import { subscribe, unsubscribe } from "./pubSub";

const __DEV__ = process.env.NODE_ENV === "development";

const configuration = {
  client_id: "contractor_portal",
  redirect_uri: `${process.env.REACT_APP_PORTAL_HOST}/authentication/callback`,
  response_type: "code",
  post_logout_redirect_uri: `${process.env.REACT_APP_PORTAL_HOST}`,
  scope: "openid user_info api1",
  authority: `${process.env.REACT_APP_API_HOST}/identity`,
  silent_redirect_uri: `${process.env.REACT_APP_PORTAL_HOST}/authentication/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
};

function Router() {
  const { oidcUser, login } = useReactOidc();

  React.useEffect(() => {
    if (!oidcUser) login();
  }, [login, oidcUser]);

  if (!oidcUser) {
    return <SessionLost />;
  }

  return (
    <BrowserRouter>
      <UserIdProvider
        fallback={
          <div>
            You have got here because you are not a contractor and you have not
            selected a contractor to view.
          </div>
        }
      >
        <StyleWrapper>
          <DateUtilsProvider utils={luxonUtils}>
            <StyleDefaults />
            <SnackProvider
              subscribe={(f) => subscribe("notification", f)}
              unsubscribe={(f) => unsubscribe("notification", f)}
            >
              <Root>
                <Switch>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/your-account">
                    <YourAccount />
                  </Route>
                  <Route exact path="/edit-personal-details">
                    <EditPersonalDetails />
                  </Route>
                  <Route exact path="/edit-bank-details">
                    <EditBankDetails />
                  </Route>
                  <Route exact path="/edit-contact-details">
                    <EditContactDetails />
                  </Route>
                  <Route exact path="/edit-login-details">
                    <EditLoginDetails />
                  </Route>
                  <Route path="/payslips">
                    <Payslips />
                  </Route>
                  <Route path="/my-wallet">
                    <MyWallet />
                  </Route>
                  <Route path="/your-points-statement">
                    <YourPointsStatement />
                  </Route>
                  <Route path="/learning-development">
                    <LearningDevelopment />
                  </Route>
                  <Route path="/tastecard">
                    <Tastecard />
                  </Route>
                  <Route path="/boxx">
                    <Boxx />
                  </Route>
                  <Route path="/pure-card">
                    <PureCard />
                  </Route>
                  <Route path="/medicash">
                    <Medicash />
                  </Route>
                  <Route path="/anytime-wages">
                    <AnytimeWages />
                  </Route>
                  <Route path="/coping-with-covid">
                    <CopingWithCovid />
                  </Route>
                  <Redirect from="*" to="/" />
                </Switch>
              </Root>
            </SnackProvider>
          </DateUtilsProvider>
        </StyleWrapper>
      </UserIdProvider>
    </BrowserRouter>
  );
}

function Loader({ children }) {
  return (
    <div className="splash-screen">
      <div className="splash-logo"></div>
      <div className="children">{children || "Connecting..."}</div>
    </div>
  );
}

function SessionLost() {
  const { login, logout } = useReactOidc();

  return (
    <Loader>
      Session lost.
      <br />
      <br />
      <Link onClick={login}>Log back in.</Link>
      <Link onClick={logout}>Log out.</Link>
    </Loader>
  );
}
function NotLoggedIn({ children }) {
  return (
    <Loader>
      Not logged in.
      <br />
      {__DEV__ && children}
      <br />
      <Link href="/">Log in.</Link>
    </Loader>
  );
}

function App() {
  return (
    <AuthenticationProvider
      configuration={configuration}
      loggerLevel={oidcLog.INFO}
      callbackComponentOverride={() => <PageLoading />}
      sessionLostComponent={SessionLost}
      noAuthorized={() => <NotLoggedIn>noAuthorized</NotLoggedIn>}
      notAuthenticated={() => <NotLoggedIn>notAuthenticated</NotLoggedIn>}
      authenticating={() => <PageLoading />}
    >
      <Router />
    </AuthenticationProvider>
  );
}

export default App;
