import React from "react";
import {
  PageContent,
  PageTitle,
  PageHeadingArea,
  Root,
} from "@backslashbuild/contractors-ui-library";

import ContractorTopBar from "../modules/contractors/ContractorTopBar";
import NavBar from "../components/NavBar";
import AcceptContractDialog from "../modules/contractors/AcceptContractDialog";

function Payslips() {
  return (
    <Root>
      <PageContent scrollable>
        <ContractorTopBar />
        <NavBar />
        <PageHeadingArea>
          <PageTitle>Payslips</PageTitle>
        </PageHeadingArea>
      </PageContent>
      <AcceptContractDialog />
    </Root>
  );
}

export default Payslips;
