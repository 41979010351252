import React from "react";
import { Footer, Flex, Text } from "@backslashbuild/contractors-ui-library";
import Logo from "../components/Logo";
import LinkExternal from "../components/LinkExternal";

export default function PageFooter() {
  return (
    <Footer
      mobile={
        <Flex spaceBetween>
          <Text>0345 034 1530</Text>
          <Logo footer />
        </Flex>
      }
    >
      <Flex spaceAround wrap>
        <Text>peoplegroupservices.com</Text>
        <Text>0345 034 1530</Text>
        <LinkExternal
          to="emailto:info@peoplegroupservices.com"
          color={"#161b45"}
        >
          <Text>info@peoplegroupservices.com</Text>
        </LinkExternal>
      </Flex>
      <Flex end>
        <Logo footer />
      </Flex>
    </Footer>
  );
}
