import {
  PageLoading,
  PanelHeading,
  PanelSpacer,
  PanelActions,
  Button,
  PanelRow,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { useGet, useGetFields, useUpdateField, useUpdateFields } from "./api";
import { isTextNullOrEmpty, buildQuery } from "./utils";
import { useUserId } from "../../UserIdContext";
import FormField from "./FormField";

const ContractorProduct = ({ contractorProduct }) => {
  const [query, setQuery] = React.useState("?take=50");
  const [edits, setEdits] = React.useState([
    { key: "IsConfirmOccupation", value: "true" },
  ]);

  const { data, loading, error } = useGetFields({
    id: contractorProduct?.id,
    formName: "contractor-product-occupation",
    queryParams: query,
  });
  const updateFields = useUpdateFields({
    id: contractorProduct?.id,
    formName: `contractor-product`,
  });

  const updateField = useUpdateField({
    id: contractorProduct?.id,
    formName: "contractor-product",
  });

  function handleConfirmOccupation() {
    updateField({ key: "IsConfirmOccupation", value: "true" }).then(() => {
      window.location.reload();
    });
  }

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return data.value.map((field) => (
      <FormField
        key={field.key}
        field={field}
        onSave={getOnSaveHandler(field.key)}
        onQuery={getOnQueryHandler}
      />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      updateField({ key, value });
    };
  }

  function getOnQueryHandler(value) {
    const dict = isTextNullOrEmpty(value) ? null : { pattern: value };
    const query = buildQuery(dict);
    setQuery(query);
  }

  function handleSelectOccupation() {
    updateFields(edits).then(() => {
      window.location.reload();
    });
  }

  if (!contractorProduct.occupationConfirmedDate) {
    return (
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          margin: 0,
          backgroundColor: "rgba(0,0,0,0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: 16,
            overflow: "hidden",
          }}
        >
          <div style={{ width: 1000 }}>
            <PanelHeading>Please Confirm Your Occupation</PanelHeading>
            <PanelSpacer />
            <PanelRow>
              For insurance purposes it is crucial that we hold an accurate
              occupation for you on file, as defined by HM Revenue & Customs.
            </PanelRow>
            <PanelRow>
              {`Your current occupation is ${contractorProduct?.occupationName} in the ${contractorProduct?.sectorName} sector.`}
            </PanelRow>
            <PanelSpacer />
            <PanelActions>
              <Button
                onClick={() => {
                  handleConfirmOccupation();
                }}
              >
                I confirm this is my occupation
              </Button>
            </PanelActions>
            <PanelRow>
              If this is not your occupation, please select the correct one
              below.
            </PanelRow>
            {handleRender()}
            <PanelSpacer />
            <PanelActions>
              <Button
                onClick={() => {
                  handleSelectOccupation();
                }}
              >
                Confirm this occupation
              </Button>
            </PanelActions>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default function ConfirmOccupationDialog() {
  const contractorId = useUserId();
  const { data, loading, error, refetch } = useGet(
    `contractor-products?contractorId=${contractorId}`
  );

  if (loading) return <PageLoading />;

  console.log("contractor products:", data);

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  if (data?.value?.items?.length > 0) {
    return (
      <>
        {data.value.items.map((item) => {
          return <ContractorProduct contractorProduct={item} />;
        })}
      </>
    );
  }

  return null;
}
