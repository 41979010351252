import {
  PageLoading,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeading,
  PanelSpacer,
  Button,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { useGetBankFields, useUpdateFields } from "./api";
import FormField from "./FormField";
import { isTextNullOrEmpty } from "./utils";
import { useUserId } from "../../UserIdContext";

export default function ContractorEditBankDetails() {
  const id = useUserId();
  const { data, loading, error } = useGetBankFields({ id });
  const updateFields = useUpdateFields({ id, formName: "" });
  const [edits, setEdits] = React.useState([]);

  if (loading) return <PageLoading />;

  function handleRender() {
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    // onSave is not being used, as we don't want to allow autosave
    return data.value.map((field) => (
      <FormField
        key={field.key}
        field={field}
        onSave={getOnSaveHandler(field.key)}
      />
    ));
  }

  function getOnSaveHandler(key) {
    return (value) => {
      setEdits([...edits.filter((e) => e.key !== key), { key, value }]);
    };
  }

  function handleSave() {
    updateFields(edits);
  }

  return (
    <Panel maxWidth="500px">
      <PanelHeading>Bank Details</PanelHeading>
      <PanelContent>
        <PanelSpacer />
        {handleRender()}
        <PanelActions>
          <Button onClick={handleSave}>Save</Button>
        </PanelActions>
      </PanelContent>
    </Panel>
  );
}
