import React from "react";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { subscribe, publish, unsubscribe } from "./pubSub";
import { isTextNullOrEmpty } from "./utils";

const apiHost = process.env.REACT_APP_API_HOST;
const service = "contractors";

export function useGet(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };
  const [{ data, loading, error }, setState] = React.useState({
    data: null,
    loading: true,
    error: null,
  });

  function runQuery() {
    return fetch(`${apiHost}/${service}/${path}`, {
      headers: {
        ...authHeaders,
      },
    })
      .then((r) => r.json())
      .then((r) =>
        setState({
          data: r,
          loading: false,
        })
      )
      .catch((e) =>
        setState({
          loading: false,
          error: e,
        })
      );
  }
  React.useEffect(() => {
    runQuery();
  }, [path]);
  return { data, loading, error, refetch: runQuery };
}

export function usePost(path) {
  const { oidcUser } = useReactOidc();
  const authHeaders = {
    Authorization: `${oidcUser.token_type} ${oidcUser.access_token}`,
  };

  const postRef = React.useRef((body) => {
    return fetch(`${apiHost}/${service}/${path}`, {
      method: "POST",
      headers: {
        ...authHeaders,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((r) => r.json());
  });

  return postRef.current;
}

// deprecriated?
export function useGetFields({ id, formName, queryParams }) {
  let path = "";
  if (!isTextNullOrEmpty(formName)) path += formName;
  if (!isTextNullOrEmpty(queryParams)) path += `/${queryParams}`;
  const query = useGet(`${id}/fields/${path}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  }, []);

  return query;
}

export function useGetPersonalDetailsFields({ id, formName }) {
  const query = useGet(`${id}/personal-details-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetLoginDetailsFields({ id, formName }) {
  const query = useGet(`${id}/login-details-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetContactDetailsFields({ id, formName }) {
  const query = useGet(`${id}/contact-details-fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetAWSBalance({ id }) {
  const query = useGet(`contractor-aws-balance?contractorId=${id}`);

  return query;
}

export function useGetPointsBalance({ id }) {
  const query = useGet(`contractor-points-balance?contractorId=${id}`);

  return query;
}

export function useGetBankFields({ id }) {
  const query = useGet(`${id}/bank-fields`);

  React.useEffect(() => {
    subscribe(`${service}.fields.update`, query.refetch);
    return () => unsubscribe(`${service}.fields.update`, query.refetch);
  });

  return query;
}

export function useGetNewFields({ contractorId, formName }) {
  const query = useGet(`${contractorId}/new/fields/${formName}`);

  React.useEffect(() => {
    subscribe(`${service}.fields.getNew`, query.refetch);
    return () => unsubscribe(`${service}.fields.getNew`, query.refetch);
  });

  return query;
}

export function useUpdateField({ id, formName }) {
  const post = usePost(`${id}/fields/${formName}`);

  const ref = React.useRef((body) => {
    return post(body).then((x) => {
      publish(`${service}.fields.update`, x);
      return x;
    });
  });

  return ref.current;
}

export function useUpdateFields({ id, formName }) {
  const post = usePost(`${id}/fields-multi/${formName}`);

  const ref = React.useRef((body) => {
    return post(body).then((x) => {
      publish(`${service}.fields.update`, x);
      return x;
    });
  });

  return ref.current;
}

export function useGetItems(path = "") {
  return useGet(`${path}`);
}
