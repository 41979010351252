import {
  Panel,
  PanelContent,
  Link,
  Label,
  BoldText,
  PageLoading,
  PanelHeading,
  PanelRow,
} from "@backslashbuild/contractors-ui-library";

import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { Link as RouterLink } from "react-router-dom";
import { useUserId } from "../../UserIdContext";

export default function ContractorContactDetailsPanel() {
  const id = useUserId();

  const { data, loading, error } = useGet(id);

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  console.log(data);

  return (
    <>
      <Panel>
        <PanelHeading
          rightAction={
            <Link component={RouterLink} to={"/edit-contact-details"}>
              Edit
            </Link>
          }
        >
          Contact details
        </PanelHeading>
        <PanelContent>
          <PanelRow>
            <Label label="Mobile Number">
              <BoldText>{data?.value?.mobileNumber}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Address">
              <BoldText>{data?.value?.address?.line1}</BoldText>
              <BoldText>{data?.value?.address?.line2}</BoldText>
              <BoldText>{data?.value?.address?.line3}</BoldText>
              <BoldText>{data?.value?.address?.town}</BoldText>
              <BoldText>{data?.value?.address?.postCode}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Preferred Contact Method">
              {data?.value?.isContactByPhone && <BoldText>Phone</BoldText>}
              {data?.value?.isContactByMobile && <BoldText>Mobile</BoldText>}
              {data?.value?.isContactByEmail && <BoldText>Email</BoldText>}
              {data?.value?.isContactByText && <BoldText>Text</BoldText>}
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Email">
              <Link href={`mailto:${data?.value?.email}`}>
                {data?.value?.email}
              </Link>
            </Label>
          </PanelRow>
        </PanelContent>
      </Panel>
    </>
  );
}
