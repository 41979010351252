import {
  PageLoading,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  Panel,
  PanelContent,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { useGetItems } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { useUserId } from "../../UserIdContext";

export default function ContractorLoginDetailsPanel() {
  const id = useUserId();

  const { data, loading, error, refetch } = useGetItems(
    `contractor-agencies?contractorId=${id}`
  );

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  console.log(data);

  return (
    <Panel width={"100%"}>
      <PanelContent width={"100%"}>
        <Table>
          <TableHeader>
            <TableHeaderColumn width="100%">Agencies</TableHeaderColumn>
          </TableHeader>
          <TableHeader>
            <TableHeaderColumn width="33%">Name</TableHeaderColumn>
            <TableHeaderColumn width="33%">Branch</TableHeaderColumn>
            <TableHeaderColumn width="33%">Product</TableHeaderColumn>
          </TableHeader>
          <TableBody>
            {data.value.items.map((contractorAgency) => (
              <TableRow>
                <TableCell width="33%">
                  {contractorAgency?.agencyName}
                </TableCell>
                <TableCell width="33%">
                  {contractorAgency?.branchAddress}
                </TableCell>
                <TableCell width="33%">
                  {contractorAgency?.preferredContractorProductName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PanelContent>
    </Panel>
  );
}
