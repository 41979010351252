import React from "react";
import {
  PageContent,
  Root,
  ResponsiveLayoutWrapper,
  PanelImage,
  Panel,
  PanelHeading,
  PanelContent,
  PanelText,
  PageTitle,
  PanelActions,
  Button,
  Text,
  PanelSpacer,
  PageHeadingArea,
} from "@backslashbuild/contractors-ui-library";
import anytimewages from "../images/mywallet/anytimewages.png";
import boxx from "../images/mywallet/boxx.png";
import cmme from "../images/mywallet/cmme.png";
import coursemix from "../images/mywallet/coursemix.png";
import edssupport from "../images/mywallet/edssupport.png";
import medicash from "../images/mywallet/medicash.png";
import myactivediscounts from "../images/mywallet/myactivediscounts.png";
import purecard from "../images/mywallet/purecard.png";
import quintil from "../images/mywallet/quintil.png";
import tastecard from "../images/mywallet/tastecard.png";
import Logo from "../components/Logo";
import ContractorTopBar from "../modules/contractors/ContractorTopBar";
import ContractorPointsBalance from "../modules/contractors/ContractorPointsBalance";
import PageFooter from "../components/PageFooter";
import NavBar from "../components/NavBar";

function MyWallet() {
  return (
    <Root>
      <PageContent scrollable>
        <ContractorTopBar />
        <NavBar />
        <PageHeadingArea>
          <PageTitle>My Wallet Rewards</PageTitle>
          <PanelSpacer />
          <Text>
            You will earn Reward Points every time you are paid through People
            Group Services, as we feel like your loyalty should be recognised.
            How you use your Reward Points is completely up to you. They can be
            redeemed against any of the benefits below. Whether you want a cash
            sum added to your wages, healthcare or even a free wage advance. The
            choice is yours.
          </Text>
          <PanelSpacer />
          <ContractorPointsBalance />
        </PageHeadingArea>
        <ResponsiveLayoutWrapper>
          <Panel>
            <PanelContent>
              <PanelImage image={medicash} />
              <PanelHeading>Medicash</PanelHeading>
              <PanelText>
                Looking after your health is the most important thing you can do
                to achieve your full potential and feel your best both at home
                and in work. Medicash Flex healthcare plan, helps you to cover
                the costs of your day to day healthcare and supports you in
                leading a healthy lifestyle.
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
          <Panel>
            <PanelContent>
              <PanelImage image={anytimewages} />
              <PanelHeading>Anytime Wages</PanelHeading>
              <PanelText>
                We’ve all been there, you’re waiting for pay day and an
                unexpected bill comes through, so what do you do? As a member of
                the My Wallet benefits scheme you can access the Anytime Wages
                service and have some of your pay within 1 hour paid straight
                into your account!
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
          <Panel>
            <PanelContent>
              <PanelImage image={tastecard} />
              <PanelHeading>Tastecard</PanelHeading>
              <PanelText>
                Start saving today with tastecard+ for just 3000 My Wallet
                Reward Points! As well as 6,000+ restaurants offering 2 for 1 or
                50% off, you’ll find 40% off cinemas, 20% off high street shops
                and lots of additional discounts including days out and
                experiences. Well worth it!
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
        </ResponsiveLayoutWrapper>
        <ResponsiveLayoutWrapper>
          <Panel>
            <PanelContent>
              <PanelImage image={boxx} />
              <PanelHeading>Boxx</PanelHeading>
              <PanelText>
                Want to lose a few extra pounds or just get fitter? Now you can
                through Boxx by redeeming your reward points for boxing, cardio,
                strength and yoga workouts. Accessible on any device and
                available in nice bitesize chunks to fit a busy lifestyle. Get
                that feel good feeling from the comfort of your own home.
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
          <Panel>
            <PanelContent>
              <PanelImage image={cmme} />
              <PanelHeading>CMME Contractor Mortgages</PanelHeading>
              <PanelText>
                Whether you’re a first time buyer, moving home or looking to
                re-mortgage our partners at CMME offer specialist advice
                specifically to contractors and the self-employed. They’ve got
                25 years of experience to share with you and will offer a
                tailored approach to your individual circumstances.
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
          <Panel>
            <PanelContent>
              <PanelImage image={myactivediscounts} />
              <PanelHeading>My Active Discounts</PanelHeading>
              <PanelText>
                If you need to gear up for some outdoor adventure or just like
                quality brands the list with My Active Discounts is extensive.
                You’ll also find great discounts on nutrition, experience days,
                coaching and development and even tropical holidays!
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
        </ResponsiveLayoutWrapper>
        <ResponsiveLayoutWrapper>
          <Panel>
            <PanelContent>
              <PanelImage image={purecard} />
              <PanelHeading>Pure Card</PanelHeading>
              <PanelText>
                Access the Pure card via My Wallet and start saving money on
                your everyday shopping via automatic monthly rebates. This means
                you could save hundreds of pounds over the year! Shop online or
                in-store – with over 70 high-street retailers, supermarkets and
                restaurants!
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
          <Panel>
            <PanelContent>
              <PanelImage image={quintil} />
              <PanelHeading>Quintil Learning Platform</PanelHeading>
              <PanelText>
                My Wallet gives you access to the Qintil lifelong learning
                network with tools to help you find, deliver, manage and share
                your professional skills and training. Access FREE compliance
                courses, hundreds of CPD courses and a wide range of
                professional eBooks.
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
          <Panel>
            <PanelContent>
              <PanelImage image={coursemix} />
              <PanelHeading>The Course Mix</PanelHeading>
              <PanelText>
                As well as the wide range of courses on the Qintil platform, we
                have a range of courses designed specifically for people working
                in the education sector. Just 700 Reward Points per course with
                11 to choose from, it’s an easy way to boost your CV.
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
        </ResponsiveLayoutWrapper>
        <ResponsiveLayoutWrapper>
          <Panel>
            <PanelContent>
              <PanelImage image={edssupport} />
              <PanelHeading>The Education Support Partnership</PanelHeading>
              <PanelText>
                Sometimes work (or just life) can be tough. A challenging
                student, an Ofsted inspection, personal financial worries; there
                are many stresses on those who work in education. Contact the
                Education Support Partnership for free, confidential help and
                support, no matter what your problem. 08000 562 561
              </PanelText>
              <PanelActions>
                <Button>FIND OUT MORE</Button>
              </PanelActions>
            </PanelContent>
          </Panel>
        </ResponsiveLayoutWrapper>
        <PageFooter />
      </PageContent>
    </Root>
  );
}

export default MyWallet;
