import React from "react";
import {
  PageContent,
  PageTitle,
  PageHeadingArea,
  Link,
  PageSubtitle,
  PanelSpacer,
} from "@backslashbuild/contractors-ui-library";
import { Link as RouterLink } from "react-router-dom";

import ContractorTopBar from "../../modules/contractors/ContractorTopBar";
import NavBar from "../../components/NavBar";
import InformationSectionButton from "../../components/InformationSection/InformationSectionButton";
import BlueBanner from "../../components/InformationSection/BlueBanner";
import FlexCenter from "../../components/InformationSection/FlexCenter";
import AcceptContractDialog from "../../modules/contractors/AcceptContractDialog";

function LearningDevelopment() {
  return (
    <>
      <ContractorTopBar />
      <PageContent scrollable>
        <NavBar />
        <PageContent border>
          <PageHeadingArea>
            <PageTitle>Learning & Development</PageTitle>
            <PanelSpacer grow />
            <InformationSectionButton
              component={RouterLink}
              to={"/my-wallet-medicash"}
            >
              Request Now DISABLED
            </InformationSectionButton>
          </PageHeadingArea>
          <br />
          <PageSubtitle>Points total: HARDCODED</PageSubtitle>
          <Link component={RouterLink} to={"your-points-statement"}>
            Points Statement
          </Link>
          <br />
        </PageContent>
        <BlueBanner>Working in the education sector?</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <PageSubtitle>
              Redeem 700 points now and choose from one of the following CPD
              courses.
            </PageSubtitle>
            <br />
            <div>
              Autism Awareness • Bullying in Schools • Challenging Behaviour in
              Young People
            </div>
            <br />
            <div>
              Child Behaviour Management • Child Safeguarding • Child
              Exploitation Awareness
            </div>
            <br />
            <div>
              Mental Health in Schools • Microsoft Excel (Advanced) • Microsoft
              Powerpoint (Advanced)
            </div>
            <br />
            <div>
              Safeguarding Children with Disabilities • SEND Teaching Methods
            </div>
          </FlexCenter>
        </PageContent>
      </PageContent>
      <AcceptContractDialog />
    </>
  );
}

export default LearningDevelopment;
