import React from "react";
import {
  PageContent,
  PageTitle,
  PageHeadingArea,
  Link,
  PageSubtitle,
  PanelSpacer,
} from "@backslashbuild/contractors-ui-library";
import { Link as RouterLink } from "react-router-dom";

import ContractorTopBar from "../../modules/contractors/ContractorTopBar";
import NavBar from "../../components/NavBar";
import InformationSectionButton from "../../components/InformationSection/InformationSectionButton";
import BlueBanner from "../../components/InformationSection/BlueBanner";
import FlexCenter from "../../components/InformationSection/FlexCenter";
import AcceptContractDialog from "../../modules/contractors/AcceptContractDialog";

function PureCard() {
  return (
    <>
      <ContractorTopBar />
      <PageContent scrollable>
        <NavBar />
        <PageContent border>
          <PageHeadingArea>
            <PageTitle>Pure Card</PageTitle>
            <PanelSpacer grow />
            <InformationSectionButton
              component={RouterLink}
              to={"/my-wallet-medicash"}
            >
              Request Now DISABLED
            </InformationSectionButton>
          </PageHeadingArea>
          <br />
          <PageSubtitle>Points total: HARDCODED</PageSubtitle>
          <Link component={RouterLink} to={"/your-points-statement"}>
            Points Statement
          </Link>
        </PageContent>
        <BlueBanner>One card, thousands of possibilities</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              Access the Pure Card via My Wallet and start saving money on your
              everyday shopping via automatic monthly rebates. This means you
              could save hundreds of pounds over the year! You can use your Pure
              Card to put extra pounds in your pocket each month, to save up for
              bigger items or even as a budgeting tool for your household.
            </div>
            <br />
          </FlexCenter>
        </PageContent>
        <BlueBanner>Using the Pure Card is simple:</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              Top up on the go – instantly add funds to your card via text,
              online or the MyPureCard app
            </div>
            <br />
            <div>
              Shop online or in-store – with over 70 high-street retailers,
              supermarkets and restaurants
            </div>
            <br />
            <div>
              Receive automatic rebates – your total rebate is automatically
              loaded onto your card the month after your spend.
            </div>
          </FlexCenter>
        </PageContent>
        <BlueBanner>Ordering your Pure Card</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              You can order your Pure Card today using 1000 points by simply
              clicking the button below and following the instructions. And
              that’s it, you’ll be all set to shop and save for the next three
              years. Follow the instructions on the Pure Card site and your card
              will be on its way to you soon. All that’s left to do is activate
              your card when it arrives in the post, retrieve your PIN - and
              then start shopping!
            </div>
            <br />
          </FlexCenter>
        </PageContent>
      </PageContent>
      <AcceptContractDialog />
    </>
  );
}

export default PureCard;
