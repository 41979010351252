import {
  Panel,
  PanelContent,
  PanelRow,
  Label,
  BoldText,
  PageLoading,
  PanelHeading,
  Link,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { Link as RouterLink } from "react-router-dom";
import { useUserId } from "../../UserIdContext";

export default function ContractorPersonalDetailsPanel() {
  const id = useUserId();

  const { data, loading, error } = useGet(id);

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  return (
    <>
      <Panel>
        <PanelHeading
          rightAction={
            <Link component={RouterLink} to={"/edit-bank-details"}>
              Edit
            </Link>
          }
        >
          Bank details
        </PanelHeading>
        <PanelContent>
          <PanelRow>
            <Label label="Bank/Building Society Name">
              <BoldText>{data?.value?.bankName}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Bank Branch Town">
              <BoldText>{data?.value?.bankBranchTown}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Account Name">
              <BoldText>{data?.value?.bankAccountName}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Account Number">
              <BoldText>{data?.value?.bankAccountNumber}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Sort Code">
              <BoldText>{data?.value?.sortCode}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="BS Roll Number">
              <BoldText>{data?.value?.bsRollNumber}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="BIC/SWIFT">
              <BoldText>{data?.value?.bicSwift}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="IBAN">
              <BoldText>{data?.value?.iban}</BoldText>
            </Label>
          </PanelRow>
        </PanelContent>
      </Panel>
    </>
  );
}
