import React from "react";
import { useGetPointsBalance } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { useUserId } from "../../UserIdContext";
import { Button } from "@backslashbuild/contractors-ui-library";

export default function ContractorAWSBalance() {
  const id = useUserId();
  const { data, loading, error } = useGetPointsBalance({ id });

  console.log("balance:");
  console.log(data);

  if (loading) return null;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  return <Button>{`MY POINTS: ${data?.value?.value}`}</Button>;
}
