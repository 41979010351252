import {
  PageLoading,
  Panel,
  PanelHeading,
  PanelSpacer,
  PanelActions,
  Button,
  CheckBox,
  PanelRow,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useGet, useUpdateField } from "./api";
import { useGet as useGetContracts } from "../contracts/api";
import { isTextNullOrEmpty } from "./utils";
import { useUserId } from "../../UserIdContext";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Contract = ({ contract }) => {
  const { data, loading, error, refetch } = useGetContracts(
    `contract/${contract?.id}/encoded`
  );

  const [
    hasCheckedApproveContract,
    setHasCheckedApproveContract,
  ] = React.useState(false);
  const [numPages, setNumPages] = React.useState(0);

  const updateField = useUpdateField({
    id: contract?.id,
    formName: "contractor-contract",
    subFormName: "contractorContract",
  });

  function handleAcceptContract() {
    updateField({ isAccepted: "true" }).then((res) => {
      if (res?.isSuccess) {
        window.location.reload();
      }
    });
  }

  if (!contract.dateAccepted) {
    return (
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          top: 0,
          left: 0,
          margin: 0,
          zIndex: 10000,
          backgroundColor: "rgba(0,0,0,0.7)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: 16,
            overflow: "hidden",
          }}
        >
          <div style={{ width: 1000 }}>
            <PanelHeading>
              Your Employment Contract & Free Benefits Package
            </PanelHeading>
            <PanelHeading>Please Approve</PanelHeading>
            <PanelSpacer />
            <PanelRow>
              We notice from our records that we have yet to receive a signed
              copy of your People Group Services employment agreement. Unless
              you confirm your intention to us then your assignment may be in
              question and any payments to you will be delayed. Please read the
              agreement and confirm to us your acceptance before entering the
              portal. Once confirmed, this message will not appear again.
            </PanelRow>
            <PanelRow>
              <div style={{ height: "50vh", overflow: "scroll" }}>
                {data?.isSuccess && data?.value && (
                  <Document
                    file={`data:application/pdf;base64,${data.value}
                `}
                    onLoadError={() =>
                      alert(
                        "There was a problem loading your contract. Please contact support."
                      )
                    }
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                  </Document>
                )}
              </div>
            </PanelRow>
            <PanelSpacer />
            <CheckBox
              checked={hasCheckedApproveContract}
              label={"I have read and approve the contract"}
              onChange={(e) => {
                setHasCheckedApproveContract(e.target.checked);
              }}
            ></CheckBox>
            <PanelActions>
              <Button
                onClick={() => {
                  handleAcceptContract();
                }}
                disabled={!hasCheckedApproveContract}
              >
                Accept Your Contract
              </Button>
            </PanelActions>
          </div>
        </div>
      </div>
    );
  }

  return null;
};
export default function AcceptContractDialog() {
  const contractorId = useUserId();
  const { data, loading, error, refetch } = useGet(
    `contractor-contracts?contractorId=${contractorId}`
  );

  if (loading) return <PageLoading />;

  console.log("contracts:", data);

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  if (data?.value?.items?.length > 0) {
    return (
      <>
        {data.value.items.map((item) => {
          return <Contract contract={item} />;
        })}
      </>
    );
  }

  return null;
}
