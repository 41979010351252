import React from "react";
import icon from "../icon.svg";
import darkIcon from "../images/logo-footer.png";

export default function Icon({ footer, ...rest }) {
  return (
    <div
      style={{
        backgroundImage: `url(${footer ? darkIcon : icon})`,
        width: footer ? 100 : 45,
        height: footer ? 20 : 45,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
      {...rest}
    ></div>
  );
}
