import React from "react";
import {
  PageContent,
  Root,
  ResponsiveLayoutWrapper,
  PageTitle,
  PageHeadingArea,
} from "@backslashbuild/contractors-ui-library";
import NavBar from "../components/NavBar";
import ContractorTopBar from "../modules/contractors/ContractorTopBar";
import ContractorPersonalDetailsPanel from "../modules/contractors/ContractorPersonalDetailsPanel";
import ContractorBankDetailsPanel from "../modules/contractors/ContractorBankDetailsPanel";
import ContractorLoginDetailsPanel from "../modules/contractors/ContractorLoginDetailsPanel";
import ContractorContactDetailsPanel from "../modules/contractors/ContractorContactDetailsPanel";
import ContractorContractsPanel from "../modules/contractors/ContractorContractsPanel";
import ContractorAgenciesTable from "../modules/contractors/ContractorAgenciesTable";
import AcceptContractDialog from "../modules/contractors/AcceptContractDialog";
import ConfirmOccupationDialog from "../modules/contractors/ConfirmOccupationDialog";
import PageFooter from "../components/PageFooter";

function YourAccount() {
  return (
    <>
      <AcceptContractDialog />
      <ConfirmOccupationDialog />
      <Root>
        <PageContent scrollable>
          <ContractorTopBar />
          <NavBar />
          <PageHeadingArea>
            <PageTitle>My Details</PageTitle>
          </PageHeadingArea>
          <ResponsiveLayoutWrapper>
            <ContractorPersonalDetailsPanel />
            <ContractorContactDetailsPanel />
          </ResponsiveLayoutWrapper>
          <ResponsiveLayoutWrapper>
            <ContractorLoginDetailsPanel />
            <ContractorBankDetailsPanel />
          </ResponsiveLayoutWrapper>
          <ResponsiveLayoutWrapper>
            <ContractorAgenciesTable />
          </ResponsiveLayoutWrapper>
          <ResponsiveLayoutWrapper>
            <ContractorContractsPanel />
          </ResponsiveLayoutWrapper>
          <PageFooter />
        </PageContent>
      </Root>
    </>
  );
}

export default YourAccount;
