import React from "react";
import {
  PageContent,
  PageTitle,
  PageHeadingArea,
  PortalWrapper,
  Table,
  TableHeader,
  TableHeaderColumn,
  TableBody,
  TableRow,
  TableCell,
  PageLoading,
} from "@backslashbuild/contractors-ui-library";

import { isTextNullOrEmpty } from "../../modules/contractors/utils";
import { useGetItems } from "../../modules/contractors/api";
import ContractorTopBar from "../../modules/contractors/ContractorTopBar";
import NavBar from "../../components/NavBar";
import AcceptContractDialog from "../../modules/contractors/AcceptContractDialog";
import { useUserId } from "../../UserIdContext";
import { utcToLocalText } from "../../modules/contractors/utils";
import Border from "../../components/Border";

function YourPointsStatement() {
  const id = useUserId();
  const { data, loading, error } = useGetItems(
    `contractor-points-statement?contractorId=${id}`
  );

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (!data.isSuccess) return data.errors.map((error) => <div>{error}</div>);

  return (
    <>
      <ContractorTopBar />
      <PageContent scrollable>
        <NavBar />
        <PageContent border>
          <PageHeadingArea>
            <PageTitle>Your Points Statement</PageTitle>
          </PageHeadingArea>
          <Border>
            <Table width={1200}>
              <TableHeader>
                <TableHeaderColumn width="100%">Points</TableHeaderColumn>
              </TableHeader>
              <TableHeader>
                <TableHeaderColumn width="300px">Date</TableHeaderColumn>
                <TableHeaderColumn width="300px">Description</TableHeaderColumn>
                <TableHeaderColumn width="300px">Points</TableHeaderColumn>
                <TableHeaderColumn width="300px">Balance</TableHeaderColumn>
              </TableHeader>
              <TableBody fullPageTable={false} footerHeight={0}>
                {data?.value?.items?.map((item, index) => {
                  return (
                    <TableRow key={index} disableHover>
                      <TableCell width="300px">
                        {utcToLocalText(item?.date)}
                      </TableCell>
                      <TableCell width="300px">{item?.description}</TableCell>
                      <TableCell width="300px">{item?.points}</TableCell>
                      <TableCell width="300px">{item?.balance}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Border>
        </PageContent>
      </PageContent>
      <AcceptContractDialog />
    </>
  );
}

export default YourPointsStatement;
