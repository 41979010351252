import {
  Panel,
  PanelContent,
  Label,
  PageLoading,
  PanelHeading,
  PanelRow,
  Link,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { Link as RouterLink } from "react-router-dom";
import { useUserId } from "../../UserIdContext";

export default function ContractorLoginDetailsPanel() {
  const id = useUserId();

  const { data, loading, error } = useGet(id);

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  console.log(data);

  return (
    <>
      <Panel>
        <PanelHeading
          rightAction={
            <Link component={RouterLink} to={"/edit-login-details"}>
              Edit
            </Link>
          }
        >
          Login details
        </PanelHeading>
        <PanelContent>
          <PanelRow>
            <Label label="Email">
              <Link href={`mailto:${data?.value?.email}`}>
                {data?.value?.email}
              </Link>
            </Label>
          </PanelRow>
        </PanelContent>
      </Panel>
    </>
  );
}
