import {
  PageSubtitle,
  PanelSpacer,
  PageTitle,
  PageBanner,
  ResponsiveLayoutWrapper,
  QuickLink,
  TopNavigationBarLink,
  Link,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import { Link as RouterLink } from "react-router-dom";
import { useUserId } from "../../UserIdContext";
import Logo from "../../components/Logo";
import home from "../../images/quicklinks/White/home.png";
import mypayslips from "../../images/quicklinks/Blue/mypayslips.png";
import mywallet from "../../images/quicklinks/Blue/mywallet.png";
import mydetails from "../../images/quicklinks/Blue/mydetails.png";
import anytimewagesQL from "../../images/quicklinks/Blue/anytimewages.png";
import webchat from "../../images/quicklinks/Blue/webchat.png";

const linkWrapper = {
  width: "100%",
  minWidth: 130,
  minHeight: "calc(300px - 30vw)",
  height: 150,
  maxHeight: 150,
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  flexShrink: 0,
  cursor: "pointer",
};

export default function ContractorTopBar() {
  const id = useUserId();

  const { data, loading, error } = useGet(id);

  function handleRender() {
    if (loading) return null;
    if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

    if (data != null && !data.isSuccess) {
      return data.errors.map((error) => <div>{error}</div>);
    }

    return (
      <PageTitle
        color={"#FFFFFF"}
      >{`Hello ${data?.value?.firstName},`}</PageTitle>
    );
  }

  return (
    <>
      <PageBanner
        quickLinks={
          <ResponsiveLayoutWrapper quickLinks>
            <div
              style={{
                display: "flex",
                flexShrink: 0,
                flex: 1,
              }}
            >
              <Link component={RouterLink} to={"/home"} style={linkWrapper}>
                <QuickLink image={home} selected title="Home"></QuickLink>
              </Link>
              <Link component={RouterLink} to={"/payslips"} style={linkWrapper}>
                <QuickLink image={mypayslips} title={"My Payslips"} />
              </Link>
              <Link
                component={RouterLink}
                to={"/my-wallet"}
                style={linkWrapper}
              >
                <QuickLink image={mywallet} title={"My Wallet"} />
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                flexShrink: 0,
                flex: 1,
              }}
            >
              <Link
                component={RouterLink}
                to={"/your-account"}
                style={linkWrapper}
              >
                <QuickLink image={mydetails} title={"My Details"} />
              </Link>
              <Link
                component={RouterLink}
                to={"/anytime-wages"}
                style={linkWrapper}
              >
                <QuickLink image={anytimewagesQL} title={"Anytime Wages"} />
              </Link>
              <Link
                component={RouterLink}
                to={"/your-account"}
                style={linkWrapper}
              >
                <QuickLink image={webchat} title={"Start Webchat"} />
              </Link>
            </div>
          </ResponsiveLayoutWrapper>
        }
      >
        <Logo />
        <PanelSpacer />
        {handleRender()}
        <PanelSpacer />
        <PageSubtitle color={"#FFFFFF"}>Welcome to your portal</PageSubtitle>
      </PageBanner>
    </>
  );
}
