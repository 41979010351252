import React from "react";
import {
  TextInput,
  Label,
  Radio,
  RadioGroup,
  Dropdown,
  DropdownOption,
  CheckBox,
  QueryDropdown,
  DatePicker,
  PanelRow,
} from "@backslashbuild/contractors-ui-library";
import { utcToYmd, textToBoolean } from "./utils";

export default function FormField({ field, onSave, onQuery, hintHandlers }) {
  let SubComponent = TextField;

  if (field.schema.type === "date") SubComponent = DateField;
  if (field.schema.type === "radio") SubComponent = RadioField;
  if (field.schema.type === "dropdown") SubComponent = DropdownField;
  if (field.schema.type === "checkbox") SubComponent = CheckboxField;
  if (field.schema.type === "textbox") SubComponent = TextBoxField;
  if (field.schema.type === "query-dropdown") SubComponent = QueryDropdownField;

  let customProps = {};
  if (field.schema.hints) {
    field.schema.hints.forEach((hint) => {
      if (hintHandlers && hintHandlers[hint]) {
        Object.assign(customProps, hintHandlers[hint]());
      }
    });
  }

  return (
    <PanelRow>
      <Label label={field.displayName}>
        <SubComponent
          field={field}
          onSave={onSave}
          onQuery={onQuery}
          {...customProps}
        />
      </Label>
    </PanelRow>
  );
}

function QueryDropdownField({ field, onSave, onQuery }) {
  const [value, setValue] = React.useState(field.value);
  const [search, setSearch] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  React.useEffect(() => {
    onQuery(search);
  }, [search]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  function handleChange(value) {
    setValue(value);
    onSave(value);
    setSearch("");
  }

  return (
    <QueryDropdown
      placeholder="Choose"
      search={search}
      onSearchChange={(search) => {
        setSearch(search);
        onQuery(search);
      }}
      value={value}
      disabled={isDisabled}
      onChange={handleChange}
      options={field.schema.options.map((option) => option.value)}
      renderOption={(value) => {
        const option = field.schema.options.find(
          (option) => value === option.value
        );
        return option ? option.displayName : "";
      }}
    ></QueryDropdown>
  );
}

function TextField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <TextInput
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        onSave(value);
      }}
    ></TextInput>
  );
}

function RadioField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <RadioGroup
      value={value}
      onChange={(e) => {
        onSave(e.target.value);
        setValue(e.target.value);
      }}
    >
      {field.schema.options.map((option) => (
        <Radio value={option.value} label={option.displayName} />
      ))}
    </RadioGroup>
  );
}

function DropdownField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <Dropdown
      value={value}
      onChange={(e) => {
        onSave(e.target.value);
        setValue(e.target.value);
      }}
    >
      {field.schema.options.map((option) => (
        <DropdownOption value={option.value}>
          {option.displayName}
        </DropdownOption>
      ))}
    </Dropdown>
  );
}

function CheckboxField({ field, onSave }) {
  const [value, setValue] = React.useState(textToBoolean(field.value));
  const label = field.schema.options[0].displayName;

  return (
    <CheckBox
      checked={value}
      label={label}
      onChange={(e) => {
        onSave(String(e.target.checked));
        setValue(e.target.checked);
      }}
    ></CheckBox>
  );
}

function DateField({ field, onSave }) {
  const [value, setValue] = React.useState(utcToYmd(field.value));
  const [isDisabled, setIsDisabled] = React.useState(field.isDisabled);

  React.useEffect(() => {
    setValue(utcToYmd(field.value));
  }, [field.value]);

  React.useEffect(() => {
    setIsDisabled(field.isDisabled);
  }, [field.isDisabled]);

  return (
    <DatePicker
      value={value}
      disabled={isDisabled}
      onChange={(v) => {
        setValue(utcToYmd(v));
        onSave(v?.isValid ? utcToYmd(v) : "");
      }}
    ></DatePicker>
  );
}

function TextBoxField({ field, onSave }) {
  const [value, setValue] = React.useState(field.value);

  React.useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <TextInput
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      onBlur={() => {
        onSave(value);
      }}
    ></TextInput>
  );
}
