import React from "react";

export default function BlueBanner({ children }) {
  return (
    <div
      style={{
        backgroundColor: "#C9DEE4",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        color: "#30B1D7",
        minHeight: 40,
      }}
    >
      {children}
    </div>
  );
}
