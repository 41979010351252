import {
  TopBar as BBTopBar,
  Flex,
  Text,
  Link,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty } from "./utils";
import LinkExternal from "../../components/LinkExternal";
import { useUserId } from "../../UserIdContext";
import { useReactOidc } from "@axa-fr/react-oidc-context";

export default function ContractorTopBar() {
  const id = useUserId();

  const { data, loading, error } = useGet(id);

  const { logout } = useReactOidc();

  if (loading)
    return (
      <BBTopBar
        mobile={
          <Flex spaceBetween>
            <Text>Loading</Text>
          </Flex>
        }
      >
        <Flex start>Loading</Flex>
      </BBTopBar>
    );
  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  return (
    <>
      <BBTopBar
        mobile={
          <Flex spaceBetween>
            <Text>0345 034 1530</Text>
            <Link onClick={logout}>Log Out</Link>
          </Flex>
        }
      >
        <Flex start>{`Welcome, ${data?.value?.firstName}`}</Flex>
        <Flex spaceAround>
          <LinkExternal to={"emailto:clientcare@peoplegroupservices.com"}>
            <Text>clientcare@peoplegroupservices.com</Text>
          </LinkExternal>
          <Text>0345 034 1530</Text>
          <Link onClick={logout}>
            <Text bold color="#FFFFFF">
              Log Out
            </Text>
          </Link>
        </Flex>
      </BBTopBar>
    </>
  );
}
