import React from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useReactOidc } from "@axa-fr/react-oidc-context";

const UserIdContext = React.createContext(null);

export function UserIdProvider({ children, fallback }) {
  const location = useLocation();
  const { oidcUser } = useReactOidc();
  const [userId, setUserId] = React.useState(null);

  React.useEffect(() => {
    const query = queryString.parse(location.search);

    if (query.userId) {
      setUserId(query.userId);
    }
  }, [location.search]);

  const impersonateUserId = userId || queryString.parse(location.search).userId;

  if (oidcUser?.profile?.is_contractor === "True") {
    return (
      <UserIdContext.Provider value={oidcUser?.profile.sub}>
        {children}
      </UserIdContext.Provider>
    );
  } else if (impersonateUserId) {
    return (
      <UserIdContext.Provider value={impersonateUserId}>
        {children}
      </UserIdContext.Provider>
    );
  } else {
    return fallback;
  }
}

export function useUserId() {
  return React.useContext(UserIdContext);
}
