import React from "react";
import MuiLink from "@material-ui/core/Link";

function InformationSectionButton({ children, onClick, ...rest }, ref) {
  return (
    <MuiLink
      ref={ref}
      onClick={(e) => {
        if (e.stopPropogation) e.stopPropogation();

        if (onClick) {
          onClick(e);
        }
      }}
      style={{
        cursor: "pointer",
        padding: "10px 24px",
        backgroundColor: "#EA205A",
        color: "#FFFFFF",
        fontSize: "14px",
        borderRadius: "4px",
      }}
      {...rest}
    >
      {children}
    </MuiLink>
  );
}

export default React.forwardRef(InformationSectionButton);
