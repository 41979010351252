import React from "react";
import {
  Content,
  Flex,
  PageContent,
  PageHeadingArea,
  PageTitle,
  ResponsiveLayoutWrapper,
  Root,
} from "@backslashbuild/contractors-ui-library";
import PageFooter from "../components/PageFooter";
import NavBar from "../components/NavBar";
import ContractorTopBar from "../modules/contractors/ContractorTopBar";
import ContractorEditPersonalDetails from "../modules/contractors/ContractorEditPersonalDetails";
import AcceptContractDialog from "../modules/contractors/AcceptContractDialog";

function EditPersonalDetails() {
  return (
    <Root>
      <PageContent scrollable>
        <ContractorTopBar />
        <NavBar />
        <PageHeadingArea>
          <PageTitle>Edit personal details</PageTitle>
        </PageHeadingArea>
        <ResponsiveLayoutWrapper>
          <ContractorEditPersonalDetails />
        </ResponsiveLayoutWrapper>
        <Flex></Flex>
        <PageFooter />
      </PageContent>
      <AcceptContractDialog />
    </Root>
  );
}

export default EditPersonalDetails;
