import {
  Panel,
  PanelActions,
  PanelContent,
  Label,
  BoldText,
  PageLoading,
  PanelHeading,
  PanelRow,
  Link,
} from "@backslashbuild/contractors-ui-library";

import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { Link as RouterLink } from "react-router-dom";
import { useUserId } from "../../UserIdContext";

export default function ContractorPersonalDetailsPanel() {
  const id = useUserId();

  const { data, loading, error } = useGet(id);

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  console.log(data);

  return (
    <>
      <Panel>
        <PanelHeading
          rightAction={
            <Link component={RouterLink} to={"/edit-personal-details"}>
              Edit
            </Link>
          }
        >
          Personal details
        </PanelHeading>
        <PanelContent>
          <PanelRow small>
            <Label small label="Title">
              <BoldText>{data?.value?.title}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow small>
            <Label small label="Gender">
              <BoldText>{data?.value?.gender}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="First Name">
              <BoldText>{data?.value?.firstName}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Middle Name">
              <BoldText>{data?.value?.middleNames}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Last Name">
              <BoldText>{data?.value?.surname}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Alias">
              <BoldText>{data?.value?.alias}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Nationality">
              <BoldText>{data?.value?.nationality}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="NI Number">
              <BoldText>{data?.value?.nino}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Date of Birth">
              <BoldText>{utcToLocalText(data?.value?.dateOfBirth)}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Student Loan Plan">
              <BoldText>
                {data?.value?.studentLoanPlan === "01"
                  ? "1"
                  : data?.value?.studentLoanPlan === "02"
                  ? "2"
                  : "None selected"}
              </BoldText>
            </Label>
          </PanelRow>
        </PanelContent>
      </Panel>
    </>
  );
}
