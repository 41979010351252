import React from "react";
import {
  Flex,
  Text,
  TopNavigationBarLink,
  TopNavigationBar as BBTopNavigationBar,
} from "@backslashbuild/contractors-ui-library";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../components/Logo";

function NavBar({ active }) {
  return (
    <>
      <BBTopNavigationBar
        mobile={
          <Flex center>
            <Logo />
          </Flex>
        }
      >
        <Flex start>
          <Logo />
        </Flex>
        <Flex spaceAround>
          <TopNavigationBarLink component={RouterLink} to="/home">
            <Text>Home</Text>
          </TopNavigationBarLink>
          <TopNavigationBarLink component={RouterLink} to="/payslips">
            <Text>My Payslips</Text>
          </TopNavigationBarLink>
          <TopNavigationBarLink component={RouterLink} to="/my-wallet">
            <Text>My Wallet</Text>
          </TopNavigationBarLink>
          <TopNavigationBarLink component={RouterLink} to="/your-account">
            <Text>My Details</Text>
          </TopNavigationBarLink>
          <TopNavigationBarLink component={RouterLink} to="/anytime-wages">
            <Text>Anytime Wages</Text>
          </TopNavigationBarLink>
        </Flex>
      </BBTopNavigationBar>
    </>
  );
}

export default NavBar;
