import {
  Label,
  BoldText,
  PageLoading,
  Panel,
  PanelHeading,
  PanelRow,
  Link,
  PanelContent,
} from "@backslashbuild/contractors-ui-library";
import React from "react";
import { useGet } from "./api";
import { isTextNullOrEmpty, utcToLocalText } from "./utils";
import { useUserId } from "../../UserIdContext";
const Contract = ({ contract }) => {
  return (
    <>
      <Panel>
        <PanelHeading>Contracts</PanelHeading>
        <PanelContent>
          <PanelRow>
            <Label label="Contract Type">
              <BoldText>
                <Link
                  onClick={() => {
                    window.open(
                      `${process.env.REACT_APP_API_HOST}/contracts/contract/${contract?.id}/render`,
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  {contract?.name}
                </Link>
              </BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Agency">
              <BoldText>{contract?.agencyName}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Date Created">
              <BoldText>{utcToLocalText(contract?.dateCreated)}</BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Date Accepted">
              <BoldText>
                {contract?.dateAccepted ? (
                  <>{utcToLocalText(contract.dateAccepted)}</>
                ) : (
                  "Not accepted"
                )}
              </BoldText>
            </Label>
          </PanelRow>
          <PanelRow>
            <Label label="Date Cancelled">
              <BoldText>
                {contract?.dateCancelled ? (
                  <>{utcToLocalText(contract.dateCancelled)}</>
                ) : (
                  "No cancelled date"
                )}
              </BoldText>
            </Label>
          </PanelRow>
        </PanelContent>
      </Panel>
    </>
  );
};

export default function ContractorContractsPanel() {
  const id = useUserId();

  const { data, loading, error } = useGet(
    `contractor-contracts?contractorId=${id}`
  );

  if (loading) return <PageLoading />;

  if (!isTextNullOrEmpty(error)) return <div>{String(error)}</div>;

  if (data != null && !data.isSuccess) {
    return data.errors.map((error) => <div>{error}</div>);
  }

  return (
    <>
      {data.value.items.map((contract, index) => (
        <Contract contract={contract} key={index} />
      ))}
    </>
  );
}
