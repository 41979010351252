import React from "react";
import {
  PageContent,
  PageTitle,
  PageHeadingArea,
  Link,
  PageSubtitle,
} from "@backslashbuild/contractors-ui-library";
import { Link as RouterLink } from "react-router-dom";

import ContractorTopBar from "../../modules/contractors/ContractorTopBar";
import NavBar from "../../components/NavBar";
import InformationSectionButton from "../../components/InformationSection/InformationSectionButton";
import BlueBanner from "../../components/InformationSection/BlueBanner";
import FlexCenter from "../../components/InformationSection/FlexCenter";
import AcceptContractDialog from "../../modules/contractors/AcceptContractDialog";

function Boxx() {
  return (
    <>
      <ContractorTopBar />
      <PageContent scrollable>
        <NavBar />
        <PageContent border>
          <PageHeadingArea>
            <PageTitle>Boxx</PageTitle>
          </PageHeadingArea>
          <FlexCenter>
            We love Boxx. Being able to do as little or as much exercise as you
            like in your own front room makes all the difference.
          </FlexCenter>
          <br />
          <PageSubtitle>Points total: HARDCODED</PageSubtitle>
          <Link component={RouterLink} to={"/your-points-statement"}>
            Points Statement
          </Link>
        </PageContent>
        <BlueBanner>How to redeem your Boxx benefit</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>There are two options for you to choose from as follows:</div>
            <br />
            <div>
              1) Redeem 400 My Wallet Reward Points for a 1 month subscription
              to Boxx We'll issue you a code which will appear on the next page
            </div>
            <br />
            <InformationSectionButton
              component={RouterLink}
              to={"/my-wallet-medicash"}
            >
              Request Now
            </InformationSectionButton>
            <br />
            <div>
              2) Redeem 2000 My Wallet Reward Points for an annual subscription
              to Boxx We'll issue you a code which will appear on the next page
            </div>
            <br />
            <InformationSectionButton
              component={RouterLink}
              to={"/my-wallet-medicash"}
            >
              Request Now
            </InformationSectionButton>
          </FlexCenter>
        </PageContent>
      </PageContent>
      <AcceptContractDialog />
    </>
  );
}

export default Boxx;
