import React from "react";
import {
  BoldText,
  Label,
  TextInput,
  Button,
  CheckBox,
} from "@backslashbuild/contractors-ui-library";
import AWSLogo from "../../components/AWSLogo";
import { usePost } from "./api";
import { formatCurrency } from "../contractors/utils";
import { useHistory } from "react-router-dom";

import "./AnytimeWagesForm.css";

const SubtractButton = ({ onClick }) => {
  return (
    <div className="noSelect" style={{ cursor: "pointer" }}>
      <svg
        width={40}
        height={40}
        viewBox="0 0 40 40"
        fill="none"
        onClick={onClick}
      >
        <rect width={40} height={40} rx={4} fill="#48EDB5" />
        <path d="M13.688 22.477v-4.688h12.046v4.688H13.687z" fill="#000" />
      </svg>
    </div>
  );
};

const AddButton = ({ onClick }) => {
  return (
    <div className="noSelect" style={{ cursor: "pointer" }}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        onClick={onClick}
      >
        <rect width="40" height="40" rx="4" fill="#48EDB5" />
        <path
          d="M17.5781 22.1641H8.25V17.9922H17.5781V8.42969H21.7969V17.9922H31.1484V22.1641H21.7969V31.6797H17.5781V22.1641Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

const GBPFormatted = ({ amount }) => {
  return <div className="amount noSelect">{`£${amount}.00`}</div>;
};

export default function AnytimeWagesForm({ id }) {
  const history = useHistory();
  const [view, setView] = React.useState("create-request");
  const [requestMade, setRequestMade] = React.useState(false);
  const [amountRequestedGBP, setAmountRequestedGBP] = React.useState(50);
  const [promoCode, setPromoCode] = React.useState(null);
  const [minAmountAvailable, setMinAmountAvailable] = React.useState(25);
  const [maxAmountAvailable, setMaxAmountAvailable] = React.useState(120);
  const [feeInMoneyPer25, setFeeInMoneyPer25] = React.useState(2);
  const [feeInPointsPer25, setFeeInPointsPer25] = React.useState(150);
  const [requestId, setRequestId] = React.useState(null);
  const [isEmergencyPayment, setIsEmergencyPayment] = React.useState(false);
  const [
    agreeToTermsAndConditions,
    setAgreeToTermsAndConditions,
  ] = React.useState(false);
  const [workedSufficientHours, setWorkedSufficientHours] = React.useState(
    false
  );
  const [
    happyToPayAdministrationFee,
    setHappyToPayAdministrationFee,
  ] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const createAWSRequest = usePost("contractor-anytimeWageRequests/create");
  const confirmAWSRequest = usePost(
    "contractor-anytimeWageRequests/confirm-request"
  );

  const createRequest = () => {
    createAWSRequest({
      contractorId: id,
      contractorAnytimeWagePromotionalCode: promoCode,
    }).then((res) => {
      console.log(res);
      if (res?.isSuccess) {
        setRequestMade(true);
        setMinAmountAvailable(res.minAmountAvailable);
        setMaxAmountAvailable(res.maxAmountAvailable);
        setFeeInMoneyPer25(res.feeInMoneyPer25);
        setFeeInPointsPer25(res.feeInPointsPer25);
        setRequestId(res.id);
      }
    });
  };

  const confirmRequest = (feePaymentType) => {
    confirmAWSRequest({
      feePaymentType,
      amountAccepted: amountRequestedGBP,
      contractorAnytimeWageRequestId: requestId,
      isEmergencyPayment,
    }).then(() => {
      history.goBack();
    });
  };

  React.useEffect(() => {
    createRequest();
  }, []);

  const intervalGBP = 5;

  function handleIncrement() {
    if (amountRequestedGBP < maxAmountAvailable) {
      setAmountRequestedGBP(amountRequestedGBP + intervalGBP);
    }
  }

  function handleDecrement() {
    if (amountRequestedGBP > minAmountAvailable) {
      setAmountRequestedGBP(amountRequestedGBP - intervalGBP);
    }
  }

  function calculateFeeInMoney() {
    if (isEmergencyPayment) {
      return 0;
    }

    const fee = Math.floor(amountRequestedGBP / 25) * feeInMoneyPer25;
    return fee;
  }

  function calculateFeeInPoints() {
    if (isEmergencyPayment) {
      return 0;
    }

    const fee = Math.floor(amountRequestedGBP / 25) * feeInPointsPer25;
    return fee;
  }

  if (!requestMade) {
    return null;
  }

  const renderView = () => {
    if (view === "create-request") {
      return (
        <div className="root">
          <AWSLogo className="image" />

          <div className="section">
            <div className="noSelect">
              You can apply for an advance between{" "}
              <b>{formatCurrency(minAmountAvailable)}</b> and{" "}
              <b>{formatCurrency(maxAmountAvailable)}</b>
            </div>
            <div className="row">
              <SubtractButton onClick={handleDecrement} />
              <GBPFormatted amount={amountRequestedGBP} />
              <AddButton onClick={handleIncrement} />
            </div>
            <div className="noSelect">
              +{`£${calculateFeeInMoney(amountRequestedGBP)}.00`} fee or FREE
              with {calculateFeeInPoints(amountRequestedGBP)} My Wallet Points
            </div>
          </div>

          <div className="section grey">
            <div className="row noSelect">
              <Label label="Override Code">
                <TextInput
                  value={promoCode}
                  onChange={(event) => setPromoCode(event.target.value)}
                />
              </Label>
              <Button
                onClick={() => {
                  createRequest();
                }}
              >
                Submit
              </Button>
            </div>
            <div className="noSelect">
              Only fill in if you’ve received a code from People Group Services.
            </div>
          </div>

          <div className="section noSelect">
            <div className="row">
              <CheckBox
                value={isEmergencyPayment}
                onChange={() => setIsEmergencyPayment(!isEmergencyPayment)}
              />
              <div>Is emergency payment</div>
            </div>
            <div className="row">
              <CheckBox
                value={agreeToTermsAndConditions}
                onChange={() =>
                  setAgreeToTermsAndConditions(!agreeToTermsAndConditions)
                }
              />
              <div>I agree to the Terms and Conditions</div>
            </div>
            <div className="row">
              <CheckBox
                value={workedSufficientHours}
                onChange={() =>
                  setWorkedSufficientHours(!workedSufficientHours)
                }
              />
              <div>
                I have worked sufficient hours between Saturday 18th - Friday
                24th Jul 2020 to cover this advance
              </div>
            </div>
            <div className="row">
              <CheckBox
                value={happyToPayAdministrationFee}
                onChange={() =>
                  setHappyToPayAdministrationFee(!happyToPayAdministrationFee)
                }
              />
              <div>
                I am happy to pay the{" "}
                {formatCurrency(calculateFeeInMoney(amountRequestedGBP))}{" "}
                administration fee for this service or FREE with{" "}
                {calculateFeeInPoints(amountRequestedGBP)} My Wallet points
              </div>
            </div>
            <Button
              onClick={() => {
                if (!agreeToTermsAndConditions) {
                  setErrorMessage("Please agree to the terms and conditions.");
                } else if (!workedSufficientHours) {
                  setErrorMessage(
                    "Please agree that you have worked sufficient hours."
                  );
                } else if (!happyToPayAdministrationFee) {
                  setErrorMessage(
                    "Please agree you are happy to pay the administration fee"
                  );
                } else {
                  setView("confirm-request");
                }
              }}
            >
              Apply Now
            </Button>
            {errorMessage && (
              <div style={{ marginTop: 15 }}>{errorMessage}</div>
            )}
          </div>
        </div>
      );
    } else if (view === "confirm-request") {
      return (
        <div className="root">
          <AWSLogo className="image" />

          <div className="section grey">
            <div className="noSelect">
              All you need to do now is check and approve your advance of:
            </div>
          </div>

          <div className="amountRequestedSection">
            {formatCurrency(amountRequestedGBP)}
          </div>

          <div className="section pink">
            <div className="pointsExplanation">
              By using {calculateFeeInPoints(amountRequestedGBP)} points this
              advance will not have an administration fee , so the amount taken
              from your pay will be {formatCurrency(amountRequestedGBP)} It's
              important you understand how this advance works, especially in
              your next payslip.
            </div>
            <Button
              onClick={() => {
                confirmRequest("Points");
              }}
            >
              Accept This Advance With Points
            </Button>
          </div>

          <div className="section grey">
            An administration fee of{" "}
            {formatCurrency(calculateFeeInMoney(amountRequestedGBP))} will be
            added to this, so the amount taken from your pay will be{" "}
            {formatCurrency(
              calculateFeeInMoney(amountRequestedGBP) + amountRequestedGBP
            )}{" "}
            It's important you understand how this advance works, especially in
            your next payslip.
            <Button
              onClick={() => {
                confirmRequest("Money");
              }}
            >
              Accept This Advance
            </Button>
          </div>

          <div className="cancelButton">
            <Button
              onClick={() => {
                setView("create-request");
                setAgreeToTermsAndConditions(false);
                setWorkedSufficientHours(false);
                setHappyToPayAdministrationFee(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      );
    }
  };

  return <div>{renderView()}</div>;
}
