import React from "react";
import {
  PageContent,
  PageTitle,
  PageHeadingArea,
  Link,
  PageSubtitle,
  PanelSpacer,
} from "@backslashbuild/contractors-ui-library";
import { Link as RouterLink } from "react-router-dom";

import ContractorTopBar from "../../modules/contractors/ContractorTopBar";
import NavBar from "../../components/NavBar";
import InformationSectionButton from "../../components/InformationSection/InformationSectionButton";
import BlueBanner from "../../components/InformationSection/BlueBanner";
import FlexCenter from "../../components/InformationSection/FlexCenter";
import AcceptContractDialog from "../../modules/contractors/AcceptContractDialog";

function Medicash() {
  return (
    <>
      <ContractorTopBar />
      <PageContent scrollable>
        <NavBar />
        <PageContent border>
          <PageHeadingArea>
            <PageTitle>Medicash</PageTitle>
            <PanelSpacer grow />
            <InformationSectionButton
              component={RouterLink}
              to={"/my-wallet-medicash"}
            >
              Request Now DISABLED
            </InformationSectionButton>
          </PageHeadingArea>
          <br />
          <PageSubtitle>Points total: HARDCODED</PageSubtitle>
          <Link component={RouterLink} to={"/your-points-statement"}>
            Points Statement
          </Link>
        </PageContent>
        <BlueBanner>Medicash a positive approach to health</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              You can receive cash back on all your healthcare bills such as
              optical, dental and everyday treatments such as physiotherapy,
              chiropody and much much more. This means that you can organise
              your finances and have the peace of mind that your healthcare
              worries are being taken care of. Take a look at the benefits table
              to see what you could be covered for. You can include up to four
              dependent children under 19 on your plan absolutely free.
            </div>
            <br />
          </FlexCenter>
        </PageContent>
        <BlueBanner>Best Doctors Interconsultation Programme</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              Get a second medical opinion from a world-leading consultant, all
              without having to leave home. With Best Doctors you can access the
              knowledge and experience of one of over 50,000 world-renowned
              consultants, experts chosen by their peers as the very best in
              their fields - giving you the peace of mind that your condition
              can be assessed by one of the best medical minds.
            </div>
            <br />
            <div>
              As a policy holder, Best Doctors would be there for you when you
              need it most.
            </div>
            <br />
          </FlexCenter>
        </PageContent>
        <BlueBanner>Medicash Extras - Save £££ on your shopping</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              Medicash Extras, gives you access to a multitude of everyday
              shopping discounts, and in particular reloadable payment cards,
              for many well-known retailers including Asda, Sainsburys, Currys,
              Debenhams and many more.
            </div>
            <br />
          </FlexCenter>
        </PageContent>
        <BlueBanner>Discounted Health Club Membership</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              To access visit www.medicash.org/gymdiscount and enter the company
              reference: MED
            </div>
            <br />
          </FlexCenter>
        </PageContent>
        <BlueBanner>Access to the Virtual GP APP</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>With the Medicash Virtual GP App you can</div>
            <br />
            <div>Consult with an NHS -registered GP by video</div>
            <br />

            <div>
              Receive advice, private prescriptions, open referrals and Fit
              Notes
            </div>
            <br />
            <div>Order NHS repeat medication in-app, with free delivery</div>
            <br />
            <div>
              Choose which GP to consult with, searching by gender, language or
              previous GP used
            </div>
            <br />
            <div>
              Have medication, prescribed in app, delivered directly to your
              chosen address with a variety of delivery options
            </div>
            <br />
            <div>Ask a GP or pharmacist questions directly through the app</div>
            <br />
            <div>
              Available to all policyholders on a discounted pay as you go
              basis.
            </div>
          </FlexCenter>
        </PageContent>
        <BlueBanner>Redeem 4000 points and register now</BlueBanner>
      </PageContent>
      <AcceptContractDialog />
    </>
  );
}

export default Medicash;
