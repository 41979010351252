import React from "react";
import {
  PageContent,
  PageTitle,
  PageHeadingArea,
  Link,
  PageSubtitle,
  PanelSpacer,
} from "@backslashbuild/contractors-ui-library";
import { Link as RouterLink } from "react-router-dom";

import ContractorTopBar from "../../modules/contractors/ContractorTopBar";
import NavBar from "../../components/NavBar";
import InformationSectionButton from "../../components/InformationSection/InformationSectionButton";
import BlueBanner from "../../components/InformationSection/BlueBanner";
import FlexCenter from "../../components/InformationSection/FlexCenter";

import banner from "../../images/mywallet/tastecardbanner.png";
import AcceptContractDialog from "../../modules/contractors/AcceptContractDialog";

function Tastecard() {
  return (
    <>
      <ContractorTopBar />
      <PageContent scrollable>
        <NavBar />
        <PageContent border>
          <PageHeadingArea>
            <PageTitle>Tastecard+</PageTitle>
            <PanelSpacer grow />
            <InformationSectionButton
              component={RouterLink}
              to={"/my-wallet-medicash"}
            >
              Sign Up Now DISABLED
            </InformationSectionButton>
          </PageHeadingArea>
          <FlexCenter>
            Register for a 12 month tastecard+ membership for 3000 My Wallet
            Reward Points
            <br />
            or just £29.99 (RRP £79.99), there are 2 and 3 year membership
            options available too.
          </FlexCenter>
          <br />
          <PageSubtitle>Points total: HARDCODED</PageSubtitle>
          <Link component={RouterLink} to={"/your-points-statement"}>
            Points Statement
          </Link>
          <br />
          <FlexCenter>
            <div
              style={{
                backgroundImage: `url(${banner})`,
                width: 616,
                height: 462,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            ></div>
          </FlexCenter>
        </PageContent>
        <BlueBanner>What is tastecard+?</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              You’ll receive 50% off food or 2 for 1 at 6,000+ restaurants
              nationwide, including Frankie & Benny’s, Yo! Sushi, Brewers Fayre,
              Beefeater, Café Rouge, Giraffe, Chiquito, Table Table, Zizzi,
              Pizza Hut, Prezzo, Pizza Express, Ed’s Easy Diners, Bella Italia,
              Wildwood, Domino’s & many more.
            </div>
            <br />
            <div>
              Other discounts included in your membership 40% off cinema tickets
              (Cineworld, VUE, ODEON, Showcase & more)
            </div>
            <br />
            <div>
              53% off days out (Alton Towers, Sea Life, Madame Tussauds, Thorpe
              Park & more)
            </div>
            <br />
            <div>
              70% off 400,000 hotels (Hilton, Radisson, Mercure, Marriott &
              more)
            </div>
            <br />
            <div>
              NEW – discounted supermarket shopping at Tesco, M&S, Asda &
              Sainsburys
            </div>
            <br />
            <div>
              NEW – 20% on the high street including Argos, Body Shop, New Look,
              & more
            </div>
            <br />
            <div>NEW – discounts on holidays with Thomas Cook</div>
            <br />
            <div>
              NEW – discounts on concerts or sporting events with Ticketmaster
            </div>
          </FlexCenter>
        </PageContent>
        <BlueBanner>How to order your card</BlueBanner>
        <PageContent border>
          <FlexCenter>
            <br />
            <div>
              Click on the sign up link below which will take you to our Taste
              Card page.
            </div>
            <br />
            <div>2) Enter your details.</div>
            <br />
            <div>3) Select the package you want to subscribe to.</div>
            <br />
            <div>
              4) Payment - For the 12 month membership we will attempt to cover
              the payment using 3000 My Wallet Reward Points. If you don't have
              a balance of 3000 Reward Points, we will deduct £29.99 from your
              payslip on 11th October. (You'll need to confirm this on the Taste
              Card page)
            </div>
            <br />
            <div>
              If you do not get paid on that date we will attempt to recover the
              fee a week later.
            </div>
            <br />
            <div>
              PLEASE NOTE We will only release Taste Cards once either points or
              a deduction of wages has been used to purchase the card.
            </div>
            <br />
            <div>
              If you want to subscribe to either the 24 or 36 month membership
              this can only be done by agreeing to a deduction from your wages.
            </div>
          </FlexCenter>
        </PageContent>
      </PageContent>
      <AcceptContractDialog />
    </>
  );
}

export default Tastecard;
